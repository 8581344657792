import React, {useEffect} from "react";
import { Container, Row, Col, Form, Input } from "reactstrap";
import Person from "../../constant/Person";
import { SocialIcon } from "react-social-icons";

function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "İletişim | Diş Hekimi Büşra Özgüneşli | Eskişehir";
  }, []);

  function openMaps() {
    window.open(Person.addressGoogleMaps, "_blank");
  }

  return (
    <Container>
      <header>
        <h1>İletişim Bilgilerimiz</h1>
        <hr></hr>
        <Row>
          {/* adres bilgisi */}
          <Col xs="12" md="6">
            <p>
              <b>Adres: </b>{" "}
              <button onClick={() => openMaps()} className="contact-button">
                {Person.address}
              </button>
            </p>
            <p>
              <b>Telefon:</b>{" "}
              <a className="contact-link" href={"tel:" + Person.phone}>
                {Person.phone}
              </a>
            </p>
            <p>
              <b>E-posta:</b>
              <a className="contact-link" href={"mailto:" + Person.email}>
                {Person.email}
              </a>
            </p>
          </Col>
          {/* sosyal medya */}
          <Col xs="12" md="6" className="text-center">
            <h3 className="text-center">Sosyal Medya Hesapları</h3>
            <SocialIcon
              className="social-icon"
              url={Person.twitter}
              target="_blank"
              rel="noopener noreferrer"
            />
            <SocialIcon
              className="social-icon"
              url={Person.instagram}
              target="_blank"
              rel="noopener noreferrer"
            />
            <SocialIcon
              className="social-icon"
              url={Person.facebook}
              target="_blank"
              rel="noopener noreferrer"
            />
          </Col>
          <hr className="mt-4"></hr>
          {/* google maps */}
          <Col xs="12">
            <iframe
              title="googlemaps"
              src={Person.addressGoogleSrc}
              width="100%"
              height="450"
              frameborder="0"
              style={{ border: 0 }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </Col>
          {/* bize ulaş */}
          <Col xs="12">
            <h3 className="text-center mt-4">Bize Ulaşın</h3>
            <Form>
              <Row>
                <Col xs="6">
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Adınız"
                  />
                </Col>
                <Col xs="6">
                  <Input
                    type="text"
                    name="surname"
                    id="surname"
                    placeholder="Soyadınız"
                  />
                </Col>

                <Col xs="12">
                  <Input
                    className="mt-2"
                    type="email"
                    name="email"
                    id="email"
                    placeholder="E-posta Adresiniz"
                  />
                </Col>

                <Col xs="12">
                  <Input
                    className="mt-2"
                    type="textarea"
                    name="message"
                    id="message"
                    placeholder="Mesajınız"
                  />
                </Col>
                <Col>
                  <button className="right mt-2 contact-button">Gönder</button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </header>
    </Container>
  );
}

export default Contact;
