import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import Slider from "../common/Slider";
import Slides from "../../constant/Slides";
import Carousel from "react-multi-carousel";

import StarRatings from "react-star-ratings";
import GallerySlides from "../../constant/GallerySlides";
import "react-multi-carousel/lib/styles.css";
import { initializeApp } from "firebase/app";

import { getFirestore, collection, getDocs } from "firebase/firestore";
import { firebaseConfig } from "../../constant/Config";

function Home() {
  const [Comments, setComments] = React.useState([]);
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const fetchPost = async () => {
    await getDocs(collection(db, "comments")).then((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setComments(newData);
      
    });
  };
  

  // async function getComments() {
  //   const commentsRef = collection(db, "comments");
  //   const commentsSnapshot = await getDocs(commentsRef);
  //   setComments(
  //     commentsSnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }))
  //   );
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Anasayfa | Diş Hekimi Büşra Özgüneşli | Eskişehir";
    fetchPost();
    // eslint-disable-next-line
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 3,
      slidesToSlide: 1,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1024 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  return (
    <div>
      <Slider
        control={true}
        autoSlide
        autoSlideInterval={6000}
        images={Slides}
      />

      {/* Hoşgeldiniz */}
      <div>
        <header className="mt-5  ">
          <Container className="home-welcome-container ">
            <Row>
              <Col xs="12" lg="7" className="home-welcome-col">
                <h1 className="home-text-primary padding-left">
                  Dt. Büşra Özgüneşli Kliniğine hoşgeldiniz.
                </h1>
                <p className="home-text-secondary padding-left">
                  Mutluluk bır gülümsemeyle başlar. Sağlık ve mutlulukla
                  gülümsemeniz için ekip olarak hizmetinizdeyiz. Kliniğimizde
                  doğru ve güncel tedavi yaklaşımlarıyla teknolojinin de
                  katkılarını kullanarak sizin için en ideal tedaviyi uygulamak
                  temel hedefimiz.
                </p>

                <div className="mt-5 padding-left w-100 ">
                  <h1 className="home-text-primary ">Neden biz?</h1>
                  <ul className="swish-list">
                    <li className="swish-list-item">Güncel tedaviler</li>
                    <li className="swish-list-item">
                      Kaliteli ve hijyenik tedavi
                    </li>
                    <li className="swish-list-item">Güler yüzlü ekip</li>
                  </ul>
                </div>
              </Col>
              <Col xs="12" lg="5">
                <img
                  src="/doktor-busra-ozgunesli.webp"
                  alt="welcome"
                  className="home-welcome-image no-movement"
                />
              </Col>
            </Row>
          </Container>
          <div></div>
        </header>
      </div>

      {/* tedavi */}
      <div className="mt-5 padding-1">
        <header>
          <Container className="">
            <Row>
              <Col xs="12" md="6">
                <img
                  src="/doktor-busra-ozgunesli-2.webp"
                  alt="welcome"
                  className="home-welcome-image no-movement"
                />
              </Col>
              <Col xs="12" md="6" className="home-welcome-col">
                <h1 className="home-text-primary mt-1">Tedavilerimiz</h1>
                <ul className="swish-list">
                  <li className="swish-list-item">İmplant Diş Tedavisi</li>
                  <li className="swish-list-item">Diş Eti hastalıkları </li>
                  <li className="swish-list-item">Kanal Tedavisi</li>
                  <li className="swish-list-item">Diş Beyazlatma</li>
                  <li className="swish-list-item">Zirkonyum Kaplama</li>
                </ul>
                <Button size="sm" color="secondary" tag={Link} to="/treatments">
                  Daha fazlası için
                </Button>
              </Col>
            </Row>
          </Container>
        </header>
      </div>

      {/* COMMENT SIDE */}
      <div className="mt-5 " hidden={Comments.length > 0 ? false : true}>
        <header>
          <Container className="">
            <h1 className="home-text-primary mt-1 ">Yorumlar </h1>
            <p className="home-text-secondary">
              Bizleri tercih edip düşüncelerini paylaşan değerli misafirlerimiz.
            </p>
            <hr></hr>
            <div className="my-own-custom-container">
              <Carousel
                className="mt-2"
                showDots
                ssr
                arrows={false}
                renderButtonGroupOutside={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                infinite
                responsive={responsive}
              >
                {Comments.map((comment) => (
                  <div
                    key={comment.id}
                    style={{ height: "100%" }}
                    className="home-card-div"
                  >
                    <Card
                      className="my-2 comment-card"
                      style={{ margin: "10px", height: "100%" }}
                    >
                      <CardBody>
                        <CardTitle tag="h5">{comment.name}</CardTitle>
                        <CardText className="center">
                          {comment.comment}
                        </CardText>
                      </CardBody>
                      <CardFooter className="text-center">
                        <StarRatings
                          rating={comment.score}
                          starRatedColor="#333"
                          starEmptyColor="white"
                          // changeRating={"false"}
                          numberOfStars={5}
                          starDimension="20px"
                        />
                      </CardFooter>
                    </Card>
                  </div>
                ))}
              </Carousel>
            </div>
          </Container>
        </header>
      </div>

      <div className="mt-5">
        <header>
          <Container>
            <h1 className="home-text-primary mt-1 ">Galeri</h1>
            <hr></hr>
            <Slider
              control={true}
              autoSlide
              autoSlideInterval={6000}
              images={GallerySlides}
              softCorner
            />
          </Container>
        </header>
      </div>

      <div className="mt-5">
        <header></header>
      </div>
    </div>
  );
}

export default Home;
