import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Navi from "../navi/Navi";
import Home from "../home/Home";
import NotFound from "../common/NotFound";
import Contact from "../contact/Contact";
import Treatments from "../treatments/Treatments";
import About from "../about/About";
import Footer from "../footer/Footer";
import "../../styles/App.css";
import Gallery from "../gallery/Gallery";


function App() {


  useState(() => {
    const script = document.createElement("script");
    script.src = "https://kit.fontawesome.com/d1e7cbb6e4.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);



  return (
    <div className="body-container">
      <Navi></Navi>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/index" component={Home} />
        <Route path="/contact" component={Contact} />
        {/* <Route path="/comment" component={Comment} /> */}
        <Route path="/gallery" component={Gallery} />
        <Route path="/treatments" component={Treatments} />
        <Route path="/about" component={About} />
        <Route component={NotFound} />
      </Switch>
      <Footer></Footer>
    </div>
  );
}

export default App;
