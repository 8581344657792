import React, { useEffect } from "react";
import { Container, Row, Col, ListGroup, ListGroupItem } from "reactstrap";
import Person from "../../constant/Person";
function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Hakkımda | Diş Hekimi Büşra Özgüneşli | Eskişehir";
  }, []);

  return (
    <Container>
      <header className="mt-5">
        <h1 className="about-h1-text">
          {Person.preName} {Person.name} {Person.surname}
        </h1>
        <hr></hr>
        <Row>
          <Col xs="12" md="6">
            <p className=" about-paragraph">{Person.resume1}</p>
            <p className=" about-paragraph">{Person.resume2}</p>
            <p className=" about-paragraph">{Person.resume3}</p>
          </Col>
          <Col xs="12" md="6">
            <div className="  ">
              <img
                alt="Doktor Büşra Özgüneşli Fotoğrafı"
                src="/doktor-busra-ozgunesli.webp"
                className=" about-img"
                style={{
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col xs="12" className="mt-5">
            <h1 className="text-center">
              {" "}
              Klinik Uygulamalar, Sertifikalar, Kongreler ve Kurslar
            </h1>
            <hr></hr>
            <ListGroup className="about-list-group">
              {Person.certificates.map((certificate) => (
                <ListGroupItem
                  action
                  className="about-list-item"
                  key={certificate.id}
                >
                  <img
                    alt="check"
                    className="about-check"
                    src="/check.svg"
                    style={{
                      height: 20,
                      width: 20,
                    }}
                  />{" "}
                  {certificate.text}
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
        </Row>

        <div className="mt-5"></div>
      </header>
    </Container>
  );
}

export default About;
