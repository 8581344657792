import React, {useEffect} from "react";
import { Container, ListGroup, ListGroupItem } from "reactstrap";
import Treatment from "../../constant/Treatment";
function Treatments() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Tedavilerimiz | Diş Hekimi Büşra Özgüneşli | Eskişehir";
  }, []);


  const [activeTab, setActiveTab] = React.useState("");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    else {
      setActiveTab("");
    }
  };

  return (
    <div>
      <div fluid className="picture-div">
        <img
          alt="logo"
          src="/treatments-wallpaper.jpg"
          className="background-image"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
        {/* <Container className="mt-5 picture-container ">
          <h1 className="container-text-h1">
            
          </h1>
        </Container> */}
      </div>
      <Container>
        <header className="mt-5">
          <h1 className="about-h1-text">
            <b>Tedavilerimiz</b>
          </h1>
          <p className="">İncelemek istediğiniz tedavinin üstüne tıklayın.</p>
          <hr></hr>
          <ListGroup>
            {Treatment.map((treatment) =>
              treatment.isEnabled ? (
                <div>
                  <ListGroupItem
                    className="treatment-list-item pointer "
                    key={treatment.id}
                    onClick={() => {
                      toggle(treatment.id);
                    }}
                  >
                    <img
                      alt="down arrow"
                      className="about-check"
                      src={
                        activeTab === treatment.id
                          ? "/up-arrow.svg"
                          : "/down-arrow.svg"
                      }
                      style={{
                        height: 20,
                        width: 20,
                      }}
                    />{" "}
                    <img
                      alt="logo"
                      src={treatment.ico}
                      style={{
                        height: 50,
                        width: 50,
                      }}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "/treatment/default.svg";
                      }}
                    />{" "}
                    {treatment.title}
                  </ListGroupItem>
                  <div
                    className="treatment-container"
                    hidden={activeTab !== treatment.id}
                  >
                    <div className="">
                      <img
                        src={treatment.image}
                        alt="Tedavi Fotoğrafı"
                        className="treatment-image no-movement"
                        align="left"
                        style={{
                          height: 300,
                          width: 300,
                          marginRight: 20,
                        }}
                      />
                      {treatment.sections.map((section) => (
                        <div>
                          <h2 className="treatment-container-h1">
                            {section.header}
                          </h2>
                          {section.texts.map((text) => (
                            <p className="treatment-container-p">{text}</p>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </ListGroup>
          <div className="mt-5"></div>
        </header>
      </Container>
    </div>
  );
}

export default Treatments;
