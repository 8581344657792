import React from "react";
import Person from "../../constant/Person";
import { Row, Col } from "reactstrap";
function Footer() {
  return (
    <div className="footer-container footer-text ">
      <Row>
        <Col className="d-none d-md-block" xs="4"></Col>
        <Col xs="12" className="text-center">
          <p className="footer-p">
            {Person.address} <br />
            <a className="footer-mail-link" href={"mailto:" + Person.email}>
              {Person.email}
            </a>{" "}
            <br />
            <a className="footer-mail-link" href={"tel:" + Person.phone}>
              {Person.phone}
            </a>
          </p>
        </Col>
        <Col className="d-none d-md-block" xs="4"></Col>
      </Row>

      <p className="footer-p-corner  ">
        ©2022 All Rights Reserved - Made by{" "}
        <a
          className="footer-link"
          target="_blank"
          href="https://github.com/Afkborn"
          rel="noopener noreferrer"
        >
          Afkborn
        </a>{" "}
      </p>
    </div>
  );
}

export default Footer;
