const Treatment = [
  {
    id: 1,
    isEnabled: false,
    title: "Çocuk Diş Hekimliği",
    ico: "/treatment/dis_eti_tedavisi.png",
    image: "https://dent35.com/wp-content/uploads/2014/06/cocuk.jpg?id=6131",
    sections: [
      {
        header: "Çocuk Diş Hekimliği",
        texts: [
          "Çocuk Diş Hekimliği: 0-14 yaş arası çocukların süt ve daimi dişlerindeki çürüklerin tedavisi, travma tedavisi, koruyucu diş hekimliği uygulamaları (flor uygulamaları, fissür örtücü uygulamaları, ağız hijyeni eğitimi) ve koruyucu, durdurucu ortodontik tedavileri kapsayan diş hekimliği dalıdır.",
        ],
      },
      {
        header: "Çocuk Diş Hekimliği nedir?",
        texts: [
          "Süt dişleri normal dişlere oranla daha çok organik madde içerirler, bu nedenle çürümeye daha yatkınlardır, daha kolay ve hızlı çürürler.Çocuklar, çürüğün erken döneminde görülebilen soğuk sıcak hassasiyeti ve hafif ağrı gibi sinyalleri zamanında yorumlayamazlar. Olayı ancak dayanılamayacak kadar ağrı olmasında fark ederler ki bu durumda çok geç kalınmış olabilir.Çocuklar ağız bakımına yetişkinler kadar dikkat edemezler. Çocuğun el becerisi, merakı ve ebeveynin tutumu diş fırçalama alışkanlığını belirler.Özellikle annelerin sıklıkla yaptığı bir hata da emzik ya da biberonu şeker, reçel vb. gibi gıdalara batırarak çocuklara vermeleri veya uyku aralarında şekerli süt, meyve suyu gibi gıdalara alıştırmalarıdır. Böylece beslenme düzensizliğinden dolayı dişler çürümeye yatkın hale gelir.",
          "Çürüğü tamamen engelleyebilecek bir aşı yada ilaç henüz geliştirilemedi. Ancak, çürük sayısını azaltmaya yönelik bazı malzemeler günümüzde kullanılmaktadır, bunlardan birisi; “fissür örtücü” dediğimiz malzemedir. Diş çürükleri genellikle azı ve küçükazı dişlerinin, çiğneyici yüzlerinde bulunan “fissür” adı verilen oluklarda başlar. Bahsettiğimiz malzemeyle olukların üzeri kapatılıp, o bölgeye mikrop, yemek artığı vs. nin sızması engellenerek çürük başlaması önlenir. Bu işlem, 6 yaşından itibaren çıkan kalıcı azı ve küçükazı dişlerine de uygulanabilir.Çürüğü engellemenin başka bir yolu da dişlerin çürüğe karşı direncini artırmaktır. Dişlere yüzeysel florür uygulanması suretiyle bu direnç kazandırılır.",
          "Süt dişlerinin birinci görevi çocuğun düzgün beslenmesini sağlamaktır. Ayrıca konuşmanın düzgün gelişimi de süt dişlerinin varlığına bağlıdır. Bunların yanında aşağıdaki gibi bir görüntü, hiç kimsenin çocuğunda görmek istemeyeceği ciddi estetik sorunlara yol açmaktadır.Süt dişleri kapladıkları alanı kendilerinin yerine gelecek olan kalıcı diş için korumakta ve kalıcı diş sürerken ona rehberlik yapmaktadırlar.Süt dişi erken çekildiği zaman bu doğal yer tutuculuk fonksiyonu da ortadan kalkmaktadır.Süt dişlerindeki çürükler tedavi edilmeli mi?Tedavi edilmeyen süt dişi çürükleri, ağrı, kötü koku, çiğneme zorluğu, beslenme bozukluğu ve çirkin görüntüye yol açar. Bu dönemdeki tedavi edilmeyen diş bozuklukları, ileride diş çarpıklığı, çene gelişiminde bozukluk ve genel sağlık problemlerine (romatizmadan kalp rahatsızlıklarına kadar) sebep olabilecektir. Dolayısıyla süt dişlerindeki çürükler, “nasıl olsa yerine yenileri gelecek” yanılgısına düşmeden tedavi edilmelidir. Süt dişlerindeki çürükler ; ağrı ile çocuğun çok küçük yaşlarda tanışmasına ve gelecekte bazı fobileri olmasına neden olabilir . Ayrıca bu çürükler süt dişlerinin çok erken kaybına neden olabilir.",
          "Çocuklarda dişlerin zarar gördüğü kazalarda zaman kaybetmeden müdahalede bulunulmalıdır. Doğru tanı konması çok önemlidir. Bunun için hekiminiz size, kazanın ne zaman ve nerede olduğunu, darbenin ne taraftan geldiğini, kaza sonrası baygınlık, kusma, hafıza kaybı vb. olup olmadığını soracaktır. Verilen bilgiler doğrultusunda en doğru tedavi uygulanabilecektir.Çocuklardaki diş yaralanmaları, bazen kalıcı dişin tamamıyla yuvasından ayrılmasına sebep olabilir. Bu durumda çıkan diş ile birlikte acilen dişhekiminize gitmelisiniz. Bu esnada diş, bir bardak sütün içinde, eğer süt mevcut değilse, temiz bir su içinde muhafaza edilmelidir.",
          "Bebeklerin, en azından ilk dört ay anne sütü ile beslenmeleri ağız çevresindeki yumuşak doku ve kas fonksiyonlarının normal gelişimini sağlayacaktır. Anne sütünün yetersiz olduğu durumlarda fizyolojik başlıklı (damaklı, kesik uçlu) biberon kullanımı gerekir.Bebekler 1 yaşından itibaren bardak ve kaşıkla beslenmeye alıştırılmalıdır.Biberonla beslenme en fazla 2 yaşına kadar devam edebilir.Parmak emme, yalancı emzik kullanma gibi alışkanlıklara 2 – 2,5 yaşına kadar izin verilebilir. Eğer parmak emme alışkanlığı mevcutsa, bunun sebebi araştırılarak 3 – 6 yaş arasında bu alışkanlık mutlaka giderilmelidir.Solunum problemleri, çene gelişmesi üzerine olumsuz etki eder. Burundan değil de, sadece ağızdan soluma durumu mevcutsa (bu durum uykuda daha iyi anlaşılır) muhakkak kulak burun boğaz uzmanına danışılmalıdır.",
          "Bebek 6-8 aylıkken, (yani ilk dişler ağızda göründüğünde) temizleme işlemi başlamalıdır. Sabah kahvaltısı sonrası ve gece yatmadan önce dişleri (en azından çiğneme yüzeylerini) temiz bir tülbent ya da gazlı bezi ıslatarak silmek, temizlemek yerinde olur.Diş fırçası kullanımına ise çocuğun arka dişlerinin çıkmasından sonra (ortalama 2,5 – 3 yaşında ) başlanması uygundur.Okul öncesi çocuklarda diş fırçalama için bir teknik uygulatmak çok zordur. Bu yaşlarda önemli olan, çocuğa diş fırçalama alışkanlığı kazandırmaktır. Çocuklar diş fırçalarken çoğu zaman dişlerin görünen ya da kolay ulaşılan yüzlerini fırçalar. Oysa çürüklerin önlenmesi için dişlerin ara yüzleri ve çiğneyici yüzeylerini çok daha iyi temizlemek gerekir. Bu nedenle fırçalamadan sonra Anne-Babanın kontrolü iyi olur.",
        ],
      },
    ],
  },
  {
    id: 2,
    isEnabled: false,
    title: "Çene Botoksu",
    ico: "/treatment/kanal-tedavisi.png",
    image: "https://dent35.com/wp-content/uploads/2014/06/cocuk.jpg?id=6131",
    sections: [
      {
        header: "Çene Botoksu",
        texts: [
          "Çene Botoksu; aşırı aktivite gösteren çene kaslarına botoks materyali kullanarak, belirli bir süre fonksiyonunun azaltılmasıdır. Bu dönem içerisinde yeme ve konuşma hareketlerinde hiçbir kısıtlanma yaşanmazken, diş sıkma aktivitesini tamamen ortadan kalkmaktadır.",
        ],
      },
      {
        header: "Diş Sıkma Nedir?",
        texts: [
          "Diş sıkma problemi çağımızda çok sık rastladığımız bir rahatsızlık olarak karşımıza çıkmaktadır. Çene kaslarının aşırı derecede aktivitesi sonucu oluşan bir harekettir. Diş sıkmanın en önemli nedeni ise strestir. Stresli bir yaşam tarzı olan kişilerde görülme olasılığı çok yüksektir. Bu rahatsızlık hayatımızın geneline yayılmakla birlikte, dönemsel olarak ta gerçekleşebilir. Önemli olan bu rahatsızlığın farkında olmak ve buna karşı bir önlem almaktır. Stresli dönemlerde bütün vücut kaslarımızda kasılma söz konusu olur, bunlara çene kasları da ( Masseter ) eşlik eder. En önemli belirtileri sabah saatlerinde çenemizde ve dişlerimizde ağrıların oluşmasıdır. Bunun dışında gün boyu yorgunluk, uyku hali, kas ağrıları, dinlenme ihtiyacı hissetmek gibi belirtilerde dikkatimizi çekebilir.",
          "Diş sıkma rahatsızlığı olanlarda çene kaslarının kasılmasıyla birlikte çene eklemine yük gelmesi, şakak ve boyun bölgesindeki kasların kasılması söz konusu olur. Bu yüzden baş ve boyun ağrılarıyla birlikte seyretmesi çok yüksek ihtimallidir.  Gece boyunca vücudun tam dinlenme pozisyonunda tüm kasların kasılmadan beklemesi gerekiyorken, strese bağlı olarak tüm kaslar kasılı bir şekilde kalması büyük bir enerji ihtiyacı gerekmektedir. Bu yüzdendir ki ertesi gün yorgunluk ve halsizlik hissedilmesi olasıdır. Peki bu rahatsızlığı fark ettiğimiz zaman ne yapmalıyız? Öncelikle yapmamız gereken diş doktorunuza başvurmak olmalıdır.          ",
          "Diş sıkma rahatsızlığının farklı yöntemler mevcuttur fakat bunların en başarılılarından bir tanesi “Çene Botoksu” (Masseter Botoksu) dur.",
        ],
      },
      {
        header: "Çene Botoksu Nedir?",
        texts: [
          "Aşırı aktivite gösteren çene kaslarına botoks materyali kullanarak, belirli bir süre fonksiyonunun azaltılmasıdır. Bu dönem içerisinde yeme ve konuşma hareketlerinde hiçbir kısıtlanma yaşanmazken, diş sıkma aktivitesini tamamen ortadan kalkmaktadır.",
        ],
      },
    ],
  },
  {
    id: 3,
    isEnabled: true,
    title: "Porselen Kaplama",
    ico: "/treatment/kaplama.png",
    image: "/treatment/porselen.webp",
    sections: [
      {
        header: "Porselen Kaplama Nedir, Hangi Durumlarda Uygulanır",
        texts: [
          "Aşırı madde kaybına uğramış dişlere veya kısmi dişsizlik durumlarında uygulanan, alt yapısı metal üst yapısı porselen olan protetik bir tedavi çeşididir. Estetik görüntüyü iyileştirir, bunun yanı sıra efektif bir çiğneme sağlar.",
        ],
      },
      {
        header: "Dayanıklı Mıdır?",
        texts: [
          "Metal ve porselen normal bir dişten daha dayanıklıdır. Fakat herhangi bir çarpma benzeri travma sonucu çatlak veya kısılmalar görülebilir. Özellikle doğru bir çiğneme hareketlerine ve iki çenenin birbirine göre olan hareketlerine göre doğru bir uygulama yapıldığı takdirde rutin kullanımda herhangi bir kırılma veya çatlama görülmesi beklenmez.",
        ],
      },
      {
        header: "Dişimden Düşer Mi?",
        texts: [
          "Bu tip kaplamaların dişe yapıştırılmasında kullanılan “siman” ismini verdiğimiz yüksek dayanıklılığa sahip özel yapıştırıcılar sayesinde dişle hem kimyasal hem de fiziksel olarak yapışarak bir bütün halinde davranır. Dikkatli bir kullanım sonucunda da uzunca yıllar herhangi bir problem olmadan kullanılabilirler.",
        ],
      },
      {
        header: "Tedavi Kaç Seans, Kaç Gün Sürer?",
        texts: [
          "Normal şartlarda üç seans sürer ve yaklaşık olarak bir hafta içerisinde tedavi bitirilir.",
          "1. Seans: Kaplama yapılacak dişlerin etrafından aşındırmalar yapılarak ölçüler alınır. (Preperasyon)",
          "2. Seans: Metal altyapının dişle uyumu kontrol edilir. (Metal Prova)",
          "3. Seans: Porselen üst yapısı tamamlanmış olan kaplamanın dişle, çenenin hareketleriyle ve çiğneme sistemiyle olan uyumu kontrol edilerek gerekli uyumlamalar yapılır. Herhangi bir problem varsa giderilir ve kaplama dişe yapıştırılır.",
        ],
      },
      {
        header: "İşlem Sırasında Ağrı Hisseder Miyim?",
        texts: [
          "Uygulamaların öncesinde bölgeye lokal anestezi yapılır ve bu anestezinin etkisi geçmeden dişlere geçici kaplamaları hazırlanır böylelikle herhangi bir ağrı, acı, hassasiyet hissedilmeden tedavi bitirilir.",
        ],
      },
      {
        header: "Tedavi Bittikten Sonra Kaplamaların Bakımı Nasıl Yapılır?",
        texts: [
          "Herhangi ekstra bir temizlik protokolüne ihtiyaç duyulmaz ve hasta normal ağız hijyeni uygulamaları olan “diş fırçalama, diş ipi kullanımı, gargara kullanımı” gibi işlemlerle gerekli temizliği rahatlıkla sağlayabilir. Bu uygulamalar dışında “düzenli diş hekimi kontrolü” yeterli olmaktadır.",
        ],
      },
    ],
  },
  {
    id: 5,
    isEnabled: true,
    title: "Dental İmplant",
    ico: "/treatment/dental-implant.svg",
    image: "/treatment/implant.jpg",
    sections: [
      {
        header: "Dental İmplant Nedir?",
        texts: [
          "Bir veya birden fazla diş eksikliklerinde ya da tamamen eksik olan dişlerdeki boşlukların, doğal diş yapısına uygun bir şekilde doldurulmasını sağlayan ve eksik dişlerin yerini alan titanyumdan yapılmış yapay diş köklerine dental implant denir. Kaybedilen dişlerin yerine yerleştirilen diş implantları, diş kayıplarına hızlı, güvenli, uzun ömürlü ve estetik çözümler getirebilmektedir.",
          "Eksik dişleriniz artık hayatınızı etkilemesin ve gülüşünüzü saklamak zorunda kalmayın. İmplant tedavinizi hemen yaptırın, hayata güvenle gülümseyin.",
        ],
      },
      {
        header: "Neden Dental İmplant?",
        texts: [
          "Dental implantlar ile dişsizliğin neden olduğu;",
          "•	Hareketli protez sayesinde ile çiğneme ve konuşma zorluğunun önüne geçmek,",
          "•	Yüz yüksekliğinde azalmayı önlemek,",
          "•	Üst dudak sarkıklığını engellemek,",
          "•	Yaşlı gülümseme görüntüsü gibi estetik yetersizliklerin önüne geçmektir.",
        ],
      },
    ],
  },
  {
    id: 6,
    isEnabled: true,
    title: "Diş Eti Hastalıkları",
    ico: "/treatment/dis_eti_tedavisi.png",
    image: "/treatment/dis-eti-hastaliklari.jpg",
    sections: [
      {
        header: "Dişeti Hastalıkları Nedir?",
        texts: [
          "Periodontal hastalıklar diş eti ve dişleri destekleyen diğer dokuları etkileyen iltihabi hastalıklardır. Erişkinlerde diş kayıplarının %70’inden periodontal hastalıklar sorumludur. Bu hastalıklar erken dönemde teşhis edildiklerinde kolay ve başarılı bir şekilde tedavi edilebilirler.",
          "Diş eti hastalıklarının önlenmesi veya tedavisi; doğal dişlerin korunması, daha rahat çiğnemenin ve daha iyi bir sindirimin sağlanması gibi diğer faydaları da beraberinde getirir.",
          "Periodontal hastalıklar diş eti iltihabı (gingivitis) ile başlar.  Bu dönemde dişetleri kanamalı, kırmızı ve hacim olarak büyümüştür. Erken dönemde çok fazla rahatsızlık vermeyebilir. Tedavi edilmezse hastalık periodontitise ilerleyerek diş eti ve dişleri destekleyen alveol kemiğinde geriye dönüşsüz hasar oluşturabilir.",
        ],
      },
      {
        header: "Diş Eti hastalığının belirtileri nelerdir?",
        texts: [
          "Diş eti hastalığının pek çok bulgusu vardır, bunlar;",
          "•	Diş fırçalama sırasında kanayan dişetleri",
          "•	Kırmızı, şiş ve hassas dişetleri",
          "•	Dişlerden kolaylıkla ayrılabilen, uzaklaşan dişetleri",
          "•	Dişler ve dişetleri arasında iltihabi akıntı",
          "•	Sallanan veya giderek birbirinden uzaklaşan dişler (dişler arasında aralıkların oluşması veya mevcut aralıkların artması)",
          "•	Isırma sırasında alt ve üst dişler arasındaki ilişkilerin değişmesi",
          "•	Bölümlü protez uyumundaki değişiklik, bozulma",
          "•	Sürekli kötü ağız kokusu",
          "Bununla beraber, periodontal hastalık hiç bir bulgu vermeden de ileri safhalara ulaşabilir. Bu nedenle düzenli aralıklarla dişhekimine gitmek son derece önemlidir.",
        ],
      },
      {
        header: "Diş Eti Hastalığının Nedeni Nedir?",
        texts: [
          "Diş eti hastalığının en önemli nedeni ‘bakteriyel diş plağı‘ adı verilen, dişler üzerinde biriken yapışkan ve renksiz film tabakasıdır. Günlük fırçalama ve diş ipliği kullanımı ile diş plağının uzaklaştırılması sağlıklı bir ağız için temel gereksinimdir. Eğer plak etkin bir şekilde dişlerden uzaklaştırılmazsa diş taşı veya tartar olarak bilinen düzensiz yüzeyli ve geçirgen bir yapıya dönüşür. Plaktaki bakteriler tarafından salınan zararlı ürünler diş etinde irritasyona neden olur.  Hastalık ilerledikçe  bakteriler daha derine; kemiğe kadar ilerler ve dişi destekleyen alveol kemiğinde de yıkım başlar. Hastalık tedavi edilmeden bırakılırsa sonunda dişler sallanır ve çekimleri bile gerekebilir.",
        ],
      },
      {
        header: "Diş Eti Estetiği Nedir?",
        texts: [
          "Günümüzde diş eti estetiği yani pembe estetik en az diş estetiği kadar önem kazanmıştır. Doğal, sağlıklı ve hoş görünümlü dişler için diş eti sağlığı ve düzgünlüğü sağlanmalıdır.",
        ],
      },
    ],
  },
  {
    id: 7,
    isEnabled: true,
    title: "Kanal Tedavisi",
    ico: "/treatment/kanal-tedavisi.svg",
    image: "/treatment/kanal3.webp",
    sections: [
      {
        header: "Kanal tedavisi ne kadar sürer, kanal tedavisi nasıl yapılır?",
        texts: [
          "Diş çürüğüne dolgu ile müdahalenin yetersiz kaldığı durumlarda, iltihaplanma varsa, diş çürüğü ilerlediyse, acılı ve ağrılıysa diş kanal tedavisi uygulanır.",
          "Dişte meydana gelen çürümeler zamanında tedavi edilmezse çürük derinlere doğru ilerler ve dişte bulunan sinirlerin iltihaplanmasına neden olur. Diş sinirlerinin iltihaplanması kişinin şiddetli diş ağrısı yaşamasına neden olabilir, dişte kararma, sıcak ve soğuğa hassasiyet gibi sorunlar ortaya çıkabilir.",
        ],
      },
      {
        header: "Diş kanal tedavisi sırasında ağrı?",
        texts: [
          "Kanal tedavilerinin ağrılı olduğu zannedilir fakat aslında kanal tedavileri basit bir dolgu işleminden daha fazla ağrı yaratmaz. Bununla beraber, her insanın ağrı eşiği farklı olduğundan hiçbir ağrı hissedilmediğinin iddia edilmesi doğru olmaz. Hasta prosedür sırasında bazı durumlarda rahatsızlık ve hassasiyet hissedebilir. Hissedilen rahatsızlık işlemden değil orada yer alan iltihabi durumdan kaynaklanır.",
        ],
      },
      {
        header: "Kanal tedavisi sonrasında ağrı?",
        texts: [
          "Kanal tedavisi ağrıyı gidermek ve dişi kurtarmak için yapılan bir tedavidir. Özellikle işlemden önce ağrı veya enfeksiyon varsa, birkaç gün süresince o dişte hassasiyet hissedilebilir. Bu duyarlılık veya rahatsızlık genellikle ibuprofen veya naproksen gibi reçetesiz satılan ağrı kesici ilaçlar ile kontrol edilebilir. Çoğu hasta ertesi gün normal aktivitelerine döner.",
        ],
      },
      {
        header: "Kanal tedavisi sonrası dikkat edilecekler",
        texts: [
          "•	Kanal tedavisi ilk seansta bitmezse, tedavi bitinceye kadar ilgili dişi kullanmaktan kaçınılmalıdır.",
          "•	Kanal tedavisi tamamen bitse bile işlemden sonra en az iki saat bir şeyler yenip içilmemelidir.",
          "•	Bir iki gün boyunca hassasiyet olabileceğinden aşırı sıcak ve soğuk içeceklerden kaçınmak hastaya rahatlık sağlar.",
          "•	Ağız sağlığı bakımı söz konusu olduğunda, düzenli aralıklarla yapılan diş fırçalama, diş ipi ve antiseptik bir gargara kullanma gibi genel bakım kurallarının yanında, diş hekimini düzenli aralıklarla ziyaret etmek de önemlidir. ",
        ],
      },
      {
        header: "Kanal tedavisi başarı oranları ve komplikasyonları",
        texts: [
          "Kök kanal tedavisi %95'ten fazla başarı oranına sahip bir prosedürdür. Kanal tedavisi görmüş birçok diş ömür boyu kullanılabilir. Bununla beraber, diş hekiminizin bir dişi temizleme ve dolgu konusundaki en iyi çabalarına rağmen, bir kök kanalında yeni enfeksiyonlar ortaya çıkabilir. ",
          "Bunun olası nedenleri:",
          "•	bir dişte normal olarak öngörülenden daha fazla kök kanalı bulunması sonucunda bunlardan birinin temizlenmemiş halde kalması",
          "•	diş kökünde saptanamayan bir çatlak",
          "•	bakterilerin restorasyonu geçerek dişin iç taraflarına ulaşması ve bölgeyi enfekte etmesi olarak sıralanabilir.",
        ],
      },
    ],
  },
  {
    id: 8,
    isEnabled: true,
    title: "İmplant Diş Tedavisi",
    ico: "/treatment/dişeti-tedavisi.png",
    image: "/treatment/implant-dis-tedavisi-min.webp",
    sections: [
      {
        header: "İmplant Diş Tedavisi nedir?",
        texts: [
          "Diş eksikliklerinde çiğneme fonksiyonunu ve estetiği tekrar sağlamak amacıyla çene kemiğine yerleştirilen, titanyumdan üretilen ve üzerine çeşitli materyallerle protez yapılabilen yapay diş kökü uygulamasına kısaca implant diş tedavisi diyebiliriz .Tek diş eksikliğinde yada çoklu diş eksikliklerinde implant diş yerleştirmeye uygun çene kemiği varsa tüm bireylere implant diş tedavisi uygulanabilir. Sağlık durumu müsait olan ileri yaşlardaki hastalara implant diş tedavisi uygulanabilmektedir. Yalnızca implant diş tedavisi nin yapılacağı bölge, lokal anestezi ile hastanın hiçbir şey hissetmeyeceği şekilde uyuşturulur ve implant  kemiğe yerleştirilir. İşlem sonrasında kullanılması gereken ilaçlar ve uygulanması gereken kurallar hastaya anlatılıp hastamız dinlenmesi için evine uğurlanır.İyileşme sürecine bağlı olarak 3, 4 veya 6 ay sonra protez diş yapımı için hasta çağrılır ve implant diş üstü protez yapımı  tamamlanır ve hastamız en hızlı ve sağlıklı biçimde yeni dişlerini rahatlıkla kullanmaları için gerekli bilgiler verilerek uğurlanır.",
          "İmplant diş, özellikle tek diş eksiklikleri nde hem uygun kemik mevcudiyeti nedeniyle hem de köprü diş tedavisinden daha koruyucu olması nedeniyle tercih edilmektedir.İmplant diş tedavisi, çoklu diş eksiklikleri nde ya da tam dişsizlik durumlarında hastanın hareketli protez yerine sabit protez kullanmasına imkan sağlamaktadır.",
        ],
      },
    ],
  },
  {
    id: 9,
    isEnabled: true,
    title: "Laminate Veneer",
    ico: "/treatment/dişeti-tedavisi.png",
    image: "/treatment/laminate-veneer.jpg",
    sections: [
      {
        header: "Laminate uygulaması nedir?",
        texts: [
          "Lamina kaplama diş, yaprak porselen olarak isimlendirilir. Lamina , ağırlıkla estetik bir amaçla yapılan tedavi yöntemidir. Diş küçültülmeden, sadece yüzeyi  hafifçe aşındırılırak bazı vakalarda ise hiç aşındırma bile yapılmadan uygulanabilen kristalizeporselensenden üretilmiş ;  0.5 - 2 mm kalınlığında olabilen porselen bir tabakadır.  İnce yapısı ile ışık geçirgenliğe sahip olmasından dolayı doğal bir görüntü sağlar. Estetik gülüş dizaynlarında en çok tercih edilen materyaldir.",
          "Bu tedavi sigara, çay, kahve alışkanlıklarından dolayı dişlerinde renk değişimi görülen kişiler tarafından oldukça sık tercih edilmektedir. Pürüzsüz yüzeyi sayesinde lamina yapımı sonrasında dişlerde renklenme minimum düzeye iner. Tedaviyi alanın isteği doğrultusunda gülüş dizaynı yapılırken ,dişlerin boyu ,rengi ,formu istenildiği gibi ayarlanabilir.",
        ],
      },
      {
        header: "Lamina Diş Kimlere Uygulanabilir?",
        texts: [
          "Dişlerinin estetiğinden, yapısından memnun olmayan gülüş tasarımı yaptırmak isteyen kişilerde,",
          "Çapraşık ve eğri diş yapısına sahip kişilerde",
          "Diastema (dişler arası boşluk )bulunan kişilerde",
          "Ön dişlerinde kırık ve aşınma olan kişilerde",
          "Yoğun olarak sigara ,kahve ,çay tüketen kişilerde diş renklenmesinde kesin çözüm sağlamak için estetik tedavi olarak uygulanabilir.",
        ],
      },
      {
        header: "Lamina Diş Bakımı Nasıl Yapılmalıdır ??",
        texts: [
          "Düzenli diş fırçalama, ağız gargarası ve diş ipi kullanımı yapıldığı müddetçe lamina dişler uzun yıllar kullanılabilir. Hastanın ön dişleriyle kuruyemiş kabuğu kırma, tırnaklarını yeme , kalem gibi sert cisimler ısırma alışkanlıklarının olmaması gerekmektedir. Diş gıcırdatan hastalarda ise lamina tedavisinin ardından gece plağı kullanımı ile  dişlerini koruma altına alarak uzun ömürlü kullanabilirler.",
          "Lamina dişler (Lamina Kaplama );  Hassas, özenli bir çalışmanın teknik , sanatsal yönü güçlü hekimler tarafından ve teknolojik laboratuvarlar eşliğinde yapılmalıdır.",
          "Laminalar kuvvetli simanlarla dişe yapıştırılır. Kendiliğinden düşmezler,ancak hekim tarafından çıkartılabilirler. Altı ayda bir hekim kontrolüne gidilmelidir.",
        ],
      },
    ],
  },
  {
    id: 10,
    isEnabled: true,
    title: "Diş Beyazlatma",
    ico: "/treatment/dis-beyazlatma.svg",
    image: "/treatment/white.webp",
    sections: [
      {
        header: "Diş Beyazlatma Nedir?",
        texts: [
          "Diş beyazlatma zaman içerisinde beyazlığını yitirmiş olan dişlerin eski görüntüsüne kavuşması için uygulanmakta olan beyazlatma yöntemidir.",
          "Zaman içerisinde dişlerin mine tabakası daha gözenekli bir yapıya dönüşür. Yoğun olarak çay, kahve, sigara ve benzeri boyar maddenin kullanılması sonucu mine ve dentin tabakasında oluşan leke ve renkleşmelerden duyulan rahatsızlık sonucu diş beyazlatma yöntemlerine başvurulmaktadır.",
          "Diş beyazlatma yöntemleri için çoğu kişi diş beyazlatan şeyleri ararken”diş beyazlatma jeli“, “diş boyama” işlemi “diş beyazlatma makinesi, diş beyazlatma bandı” gibi ürünleri ve makineleri araştırmaktadır. Bu yöntemler kesinlikle ve kesinlikte sağlıklı değildir. Diş beyazlatma işlemi doktorlar tarafından gerçekleştirilmektedir.",
          "Hiçbir şekilde doktor onayı olmadan jel, boya gibi ürünler kullanmamanızı tavsiye ederiz. Jel ile diş beyazlatma dişlerinize kalıcı hasarlar verebilir.",
          "En etkili diş beyazlatma yöntemi doktor kontrolünde gerçekleşmektedir. Diş beyazlatma evde bilinçsizce yapılmamalıdır.",
        ],
      },
    ],
  },
  {
    id: 11,
    isEnabled: true,
    title: "Rubber-Dam",
    ico: "/treatment/rubber-dam.svg",
    image: "/treatment/rubber-dam.webp",
    sections: [
      {
        header: "Rubber-Dam Nedir?",
        texts: [
          "Rubber-Dam esnek plastik malzemeden üretilmiş ince bir örtüdür.",
          "Tamamen İZOLE bir ortam gerektiren tedavilerde Rubber-Dam, dişleri ağız içinde izole etmek için kullanılır.",
          "Bu uygulama özellikle Kanal tedavisinde, adeziv restorasyonlarda (Estetik Dolgu, İnley, Onley gibi), diş beyazlatma uygulamalarında ve amalgam dolgular çıkarılırken büyük önem taşır.",
        ],
      },
      {
        header: "Rubber-Dam Ne İçin Kullanılır?",
        texts: [
          "Rubber-Dam sayesinde dişin, bakteri ve tükürük ile olan teması kesilir. Ayrıca hastanın dudağı, dili ve yanağı pamuk ve el aleti kullanılmadan Rubber-Dam sayesinde ekarte edilir ve çalışılan bölgenin daha fazla ışık alması sağlanır.",
          "Bu sayede, tamamen kuru bir ortam oluşması, Diş Hekiminizin tedavi alanını daha iyi görmesini ve titizce çalışmasını kolaylaştırır.",
          "Aynı zamanda, tedavi sırasında oluşan atıklar veya kullanılan yabancı cisim maddelerinin (amalgam dolgu parçaları, sıvılar, ilaçlar, kanal tedavisi aletleri gibi) hasta tarafından yutulması veya aspire edilmesi (nefes borusuna kaçma) engellenir.",
        ],
      },
    ],
  },
  {
    id: 4,
    isEnabled: true,
    title: "Zirkonyum Kaplama",
    ico: "/treatment/kaplama.png",
    image: "/treatment/zirkonyumkron.jpg",
    sections: [
      {
        header: "Zirkonyum Kaplama Nedir?",
        texts: [
          "Zirkonyum kaplama tedavisi metal seramik porselen kaplama tedavisinin alternatifi niteliği taşımaktadır. Metal seramik porselen kaplama tedavisinin dezavantajı olan metal yorgunluğu ve metalin alttan yansıması zirkonyum kaplamada mevcut değildir. Zirkonyum kaplama tedavisinin iki çeşidi vardır. Bir tanesi tamamen zirkonyum olan monoblok zirkonyum kaplama bir diğeri de zirkonyum altyapı üstü porselen kaplamadır. Monoblok zirkonyum daha dayanıklı olmakla birlikte zirkonyum altyapı üstü porselen daha estetik bir görünümdedir. ",
        ],
      },
      {
        header: "Hangi Durumlarda Uygulanır?",
        texts: [
          "Zirkonyum kaplama tedavisi normal dişlerde uygulanabileceği gibi implant üstü dişlerde de uygulanabilmektedir. Zirkonyum materyalinin kırılma dayanımı çok yüksektir. Aynı zamanda zirkonyum materyali üzerinde oluşan mikro çatlakları kendi kendine onarmaktadır. Diş hekimleri dişleri kesip ufalttıktan sonra dijital veya konvansiyonel yöntemlerle ölçü almakta ve laboratuvara yollamaktadır. Dişlerin tamamen takılması 2 haftayı bulabilmektedir. Altyapı ve üstyapı provaları gerekmekte ve hastanın estetik istekleri bu provalarda hasta ile kararlaştırılmaktadır. Diş formları, diş renkleri hasta ile karar verilerek istenilen gülüşe ulaşılmaktadır.",
        ],
      },
    ],
  },
];

export default Treatment;
