import React, {  useEffect } from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import pic404 from "../../icons/404.svg";

function NotFound() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Sayfa Bulunamadı | Diş Hekimi Büşra Özgüneşli | Eskişehir";
  }, []);
  
  return (
    <Container fluid className="fullscreen-error">
      <div className="text-center fullscreen-center">
        <img src={pic404} style={{ height: 300, width: 300 }} alt="404" />
        <h3 className="notfound-text">
          {" "}
          Böyle bir sayfa bulunamadı, hadi seni anasayfaya götürelim{" "}
        </h3>
        <div className="  mt-5">
          <Link className="notfound-link" to={"/"}>
            Anasayfaya gitmek için tıkla
          </Link>
        </div>
      </div>
    </Container>
  );
}


export default NotFound;