const GallerySlides = [
  {
    id: 1,
    src: "/gallery_slide/slide1.webp",
    text: "",
  },
  {
    id: 2,
    src: "/gallery_slide/slide2.webp",
    text: "",
  },
  {
    id: 3,
    src: "/gallery_slide/slide3.webp",
    text: "",
  },
  {
    id: 4,
    src: "/gallery_slide/slide4.webp",
    text: "",
  },
  {
    id: 5,
    src: "/gallery_slide/slide5.webp",
    text: "",
  },
];

export default GallerySlides;
