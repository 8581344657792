const Person = {
  preName: "Dt.",
  name: "Büşra",
  surname: "Özgüneşli",
  address: "Özgüneşli Klinik Emek, Ziya Gökalp Cd. No:79/A ",
  addressGoogleMaps: "https://goo.gl/maps/mm1i7fXoZunsDDgDA",
  addressGoogleSrc:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12269.66445392668!2d30.555819!3d39.7527648!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cc3d3b4488def9%3A0x919c7c4765751330!2zRGnFnyBIZWtpbWkgQsO8xZ9yYSDDlnpnw7xuZcWfbGk!5e0!3m2!1str!2str!4v1675182574817!5m2!1str!2str",
  email: "ebusraozgun@gmail.com",
  phone: "552 289 83 82",
  facebook: "https://www.facebook.com/",
  instagram: "https://www.instagram.com/dt.busraozgunesli",
  twitter: "https://twitter.com/",
  certificates: [
    { id: 1, text: "Porselen Zirkonyum Tedavileri" },
    { id: 2, text: "Endodontik ve Restoratif Tedaviler" },
    {
      id: 3,
      text: "'Posterior Kompozitler' TDB Akademi ve Uşak Diş Hekimleri Odası (2018)",
    },
    { id: 4, text: "25. Uluslararası İZDO Bilimsel Kongre ve Sergisi (2018)" },
    { id: 5, text: "TDB Uşak Diş Hekimleri Odası Öğrenci Sempozyumu (2019)" },
    {
      id: 6,
      text: "1. Genç Diş Hekimleri Derneği Ulusal Öğrenci Kongresi (2021)",
    },
  ],
  resume1: "1997 yılında Eskişehir'de doğdum, ilköğretim hayatıma 1. Hava İkmal Bakım Merkezi İlköğretim Okulu'nda başladım, ortaöğretimi ise Eskişehir Anadolu Lisesi'nde tamamladım. 2016 yılında Uşak Üniversitesi Diş Hekimliği Fakültesi'ni kazandım. 'Ortognatik Cerrahide Preoperatif Plan ve Postoperatif Sonuçlarla İlgili Literatürün İncelemesi' konulu tezimle 2021 yılında lisans eğitimimi tamamladım. Mezuniyetimden sonra 1 yıl Özel İstanbul Modelist Ağız ve Diş Sağlığı Merkezi'nde diş hekimi olarak görev yaptım. 2023 yılı itibariyle Eskişehir de kendi kliniğimde siz değerli hastalarıma hizmet vermeye başladım.",
  resume2: "Uyguladığım tedaviler arasında başlıca Gülüş tasarımı, İmplantüstü Restorasyonlar, İmplant cerrahisi, Kanal tedavisi ve  Zirkonyum kronlar.",
  resume3: "",
};

export default Person;
