import React, { useRef, useEffect } from "react";

import useSlider from "./useSlider";

const Slider = ({ images, autoSlide, autoSlideInterval, control, softCorner }) => {
  const slideImage = useRef(null);
  const slideText = useRef(null);
  const { goToPreviousSlide, goToNextSlide } = useSlider(
    slideImage,
    slideText,
    images
  );
  useEffect(() => {
    if (autoSlide) {
      const interval = setInterval(() => {
        goToNextSlide();
      }, autoSlideInterval);
      return () => clearInterval(interval);
    }
  }, [autoSlide, autoSlideInterval, softCorner, goToNextSlide]);

  return (
    <div className={softCorner ? "slider slider--soft-corner" : "slider slider-image"} ref={slideImage}>
      <div className="slider--content">
        {control !== false ? (
          <button onClick={goToPreviousSlide} className="slider__btn-left">
            <i className="fas fa-angle-left"></i>
          </button>
        ) : null}

        <div className="slider--feature">
          <h1 className="feature--title" ref={slideText}>
            .
          </h1>
        </div>

        {control !== false ? (
          <button onClick={goToNextSlide} className="slider__btn-right">
            <i className="fas fa-angle-right"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default Slider;
