import React from "react";
import { NavbarBrand, Navbar, Nav, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
function Navi() {
  const [isOpen, setIsOpen] = React.useState(false);
  let history = useHistory();

  function changePage(page) {
    history.push(page);
  }

  return (
    <div>
      <Navbar className="nav-container w-container navbar-color">
        <NavbarBrand
          className="pointer navbar-brand"
          onClick={() => changePage("/")}
        >
          <img
            alt="logo"
            src="/logo/logo.svg"
            style={{
              height: 40,
              width: 40,
            }}
          />{" "}
          <span className="nav-text link-brand">Dt. Busra Özgünesli</span>
          {/* Dt. Büşra Özgüneşli */}
        </NavbarBrand>

        <Nav className="d-none d-md-block ">
          {/* GENİŞ */}
          <Row>
            <Col>
              <Link className="link pointer" to="/about">
                Hakkımda
              </Link>
            </Col>
            <Col>
              <Link className="link pointer" to="/treatments">
                Tedavilerimiz
              </Link>
            </Col>
            {/* <Col>
              <Link className="link pointer" to="/comment">
                Yorumlar
              </Link>
            </Col> */}
            {/* <Col>
              <Link className="link pointer" to="/gallery">
                Galeri
              </Link>
            </Col> */}
            <Col>
              <Link className="link pointer" to="/contact">
                İletişim
              </Link>
            </Col>
          </Row>
        </Nav>

        <Nav className="d-block d-sm-block d-md-none">
          <img
            alt="logo"
            src="/menu-button.svg"
            onClick={() => setIsOpen(!isOpen)}
            className="filter-white pointer  menu-button"
            style={{
              height: 22,
              width: 22,
            }}
          />
        </Nav>
        
      </Navbar>

      <div hidden={!isOpen}>
        <Row className="text-center row-center-h ">
          <Col className="border rounded-4 w-75 mt-3" xs="12">
            <Link
              className="link-mobil-nav pointer"
              onClick={() => setIsOpen(false)}
              to="/about"
            >
              Hakkımda
            </Link>
          </Col>

          <Col xs="12" className="border rounded-4 w-75 mt-3">
            <Link
              className="link-mobil-nav pointer  "
              onClick={() => setIsOpen(false)}
              to="/treatments"
            >
              Tedavilerimiz
            </Link>
          </Col>

          {/* <Col xs="12" className="border rounded-4 w-75 mt-2">
            <Link
              className="link-mobil-nav pointer"
              onClick={() => setIsOpen(false)}
              to="/gallery"
            >
              Galeri
            </Link>
          </Col> */}

          <Col xs="12" className="border rounded-4 w-75 mt-3 mb-3">
            <Link
              className="link-mobil-nav pointer"
              onClick={() => setIsOpen(false)}
              to="/contact"
            >
              İletişim
            </Link>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Navi;
