const Slides = [
    {
      id: 1,
      src: "/slide/slide1.webp",
      text: "",
    },
    {
      id: 2,
      src: "/slide/slide2.webp",
      text: "",
    },
    {
      id: 3,
      src: "/slide/slide3.webp",
      text: "",
    },
  ];

export default Slides;
